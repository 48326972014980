import { toast } from "react-toastify"
import { ApiResult } from "../types/ApiResult"
import { getAuthCookies } from "./credentials"

// getリクエスト
export const get = async <T>(url: string, params: any): Promise<ApiResult<T>> => {
  // URLSearchParamsでクエリパラメータ文字列を生成するとnullとundefinedがそのまま含まれてしまうため削除
  for (const qk of Object.keys(params)) {
    if (params[qk] === null || params[qk] === undefined || params[qk] === "") {
      delete params[qk]
    }
  }
  const { accessToken, refreshToken } = getAuthCookies();
  const apiKey = process.env.REACT_APP_API_KEY;
  const query_params = new URLSearchParams()
  Object.entries(params).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      value.forEach((item) => {
        query_params.append(key, item)
      })
    } else {
      query_params.append(key, String(value))
    }
  })
  // query_paramsに .toString() つける必要あるかも
  const data = await http(`${url}?${query_params}`, {
    method: "GET",
    headers: { "Content-Type": "application/json", accessToken: accessToken, refreshToken: refreshToken, "Authorization": apiKey || "hoge", "tokenId": params?.tokenId || "" }
  });
  const response = await handleResponse(data)
  return response.ok ? { ...response, data: response.data as T } : response
}
// getリクエスト
export const getMeAndRefreshAuthTokenUser = async <T>(url: string): Promise<ApiResult<T>> => {
  const apiKey = process.env.REACT_APP_API_KEY;
  const { accessToken, refreshToken } = getAuthCookies()
  const data = await http(url, {
    method: "GET",
    headers: { "Content-Type": "application/json", accessToken: accessToken, refreshToken: refreshToken, "Authorization": apiKey || "hoge" },
  })
  const response = await handleResponse(data)
  return response.ok ? { ...response, data: response.data as T } : response
}

// getリクエスト (個別)
export const find = async <T>(url: string): Promise<ApiResult<T>> => {
  const apiKey = process.env.REACT_APP_API_KEY;
  const { accessToken, refreshToken } = getAuthCookies()
  const data = await http(url, {
    method: "GET",
    headers: { "Content-Type": "application/json", accessToken: accessToken, refreshToken: refreshToken,"Authorization": apiKey || "hoge" },
  })
  const response = await handleResponse(data)
  return response.ok ? { ...response, data: response.data as T } : response
}

// postリクエスト
export const post = async <T>(url: string, body: any): Promise<ApiResult<T>> => {
  const apiKey = process.env.REACT_APP_API_KEY;
  const { accessToken, refreshToken } = getAuthCookies()
  const data = await http(url, {
    method: "POST",
    headers: { "Content-Type": "application/json", accessToken: accessToken, refreshToken: refreshToken, "Authorization": apiKey || "hoge" },
    body: JSON.stringify(body),
  })
  const response = await handleResponse(data)
  return response.ok ? { ...response, data: response.data as T } : response
}

const handleResponse = async <T>(response: Response): Promise<ApiResult<T>> => {
  const { accessToken, refreshToken } = getAuthCookies()

  const isJson = response.headers.get("content-type")?.includes("application/json")
  const data = isJson ? await response.json() : null

  const ContentDisposition = response.headers.get("Content-Disposition")

  if (!response.ok) {
    // サーバー側ですべて配列にするのがよさそう
    if (typeof data.message === "string") {
      toast.error(data.message, { autoClose: 5000 })
    } else {
      data.message.map((m: string) => {
        toast.error(m, { autoClose: 5000 })
      })
    }

    return {
      ok: false,
    }
  }

  // 正常終了
  return {
    ok: true,
    headers: { "Content-Disposition": ContentDisposition, accessToken: accessToken, refreshToken: refreshToken },
    data: data,
  }
}

const http = async (url: string, init?: RequestInit): Promise<Response> => {
  const response = await fetch(url, init)
  return response
}
